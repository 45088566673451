<template>
  <b-card-code title="Modifier Groupe">
    <b-card-text>
      <span>Veuillez modifier les coordonnées du Groupe </span>
    </b-card-text>
    <!-- form -->
    <validation-observer
      v-if="certificates"
      ref="simpleRules"
    >
      <b-form>
        <b-row>

          <b-col md="6">
            <b-form-group
              label="Label"
              label-for="Label"
            >
              <validation-provider
                #default="{ errors }"
                name="Label"
                rules="required"
              >
                <b-form-input
                  v-model="certificates.label"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Label"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Code"
              label-for="Code"
            >
              <validation-provider
                #default="{ errors }"
                name="Code"
                rules="required"
              >
                <b-form-input
                  v-model="certificates.code"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Code"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Frais d'inscription"
              label-for="Frais d'inscription"
            >
              <validation-provider
                #default="{ errors }"
                name="Frais d'inscription"
                rules="required"
              >
                <b-form-input
                  v-model="certificates.registration_fees"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Frais d'inscription"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Frais d'examen"
              label-for="Frais d'examen"
            >
              <validation-provider
                #default="{ errors }"
                name="Frais d'examen"
                rules="required"
              >
                <b-form-input
                  v-model="certificates.exam_fees"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Frais d'examen"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Niveau"
              label-for="Niveau"
            >
              <validation-provider
                #default="{ errors }"
                name="Niveau"
                rules="required"
              >
                <b-form-input
                  v-model="certificates.level"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Niveau"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-form-group
            label="Active"
            label-cols="10"
          >
            <b-form-checkbox
              v-model="certificates.is_active"
              class="mr-0 mt-50"
              name="is-active"
              switch
              inline
            />
          </b-form-group>

          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click.prevent="editCertificat"
            >
              Modifier
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

// import vSelect from 'vue-select'

import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
  BCardText /*  */,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    /* BFormTextarea, */
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,

  },
  data() {
    return {
      certificates: {},
      required,
    }
  },
  created() {
    this.getCertificat()
  },
  methods: {

    async getCertificat() {
      this.load = 'true'
      const { id } = this.$route.params
      const response = await axios.get(`/api/certificates/detail/${id}`)
      this.certificates = response.data
      this.load = 'false'
    },
    async editCertificat() {
      this.$refs.simpleRules.validate().then(async success => {
        const { id } = this.$route.params
        if (success) {
          try {
            await axios
              .put(
                `/api/certificates/update/${id}/`,
                {
                  label: this.certificates.label,
                  code: this.certificates.code,
                  registration_fees: this.certificates.registration_fees,
                  exam_fees: this.certificates.exam_fees,
                  is_active: this.certificates.isActive,
                  level: this.certificates.level,
                },
              )
            this.$router.push('/Cetrificats')
            setTimeout(() => {
              this.showToast('success', 'top-center', 'Certificat Modifié avec succés')
            }, 1000)
          } catch (err) {
            setTimeout(() => {
              this.showToast('danger', 'top-center', err.toString())
            }, 1000)
          }
        }
      })
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
