var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',{attrs:{"title":"Modifier Groupe"}},[_c('b-card-text',[_c('span',[_vm._v("Veuillez modifier les coordonnées du Groupe ")])]),(_vm.certificates)?_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Label","label-for":"Label"}},[_c('validation-provider',{attrs:{"name":"Label","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Label"},model:{value:(_vm.certificates.label),callback:function ($$v) {_vm.$set(_vm.certificates, "label", $$v)},expression:"certificates.label"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,250228699)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Code","label-for":"Code"}},[_c('validation-provider',{attrs:{"name":"Code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Code"},model:{value:(_vm.certificates.code),callback:function ($$v) {_vm.$set(_vm.certificates, "code", $$v)},expression:"certificates.code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3524168987)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Frais d'inscription","label-for":"Frais d'inscription"}},[_c('validation-provider',{attrs:{"name":"Frais d'inscription","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Frais d'inscription"},model:{value:(_vm.certificates.registration_fees),callback:function ($$v) {_vm.$set(_vm.certificates, "registration_fees", $$v)},expression:"certificates.registration_fees"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2258916300)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Frais d'examen","label-for":"Frais d'examen"}},[_c('validation-provider',{attrs:{"name":"Frais d'examen","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Frais d'examen"},model:{value:(_vm.certificates.exam_fees),callback:function ($$v) {_vm.$set(_vm.certificates, "exam_fees", $$v)},expression:"certificates.exam_fees"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,215495094)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Niveau","label-for":"Niveau"}},[_c('validation-provider',{attrs:{"name":"Niveau","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Niveau"},model:{value:(_vm.certificates.level),callback:function ($$v) {_vm.$set(_vm.certificates, "level", $$v)},expression:"certificates.level"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1274340365)})],1)],1),_c('b-form-group',{attrs:{"label":"Active","label-cols":"10"}},[_c('b-form-checkbox',{staticClass:"mr-0 mt-50",attrs:{"name":"is-active","switch":"","inline":""},model:{value:(_vm.certificates.is_active),callback:function ($$v) {_vm.$set(_vm.certificates, "is_active", $$v)},expression:"certificates.is_active"}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.editCertificat.apply(null, arguments)}}},[_vm._v(" Modifier ")])],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }